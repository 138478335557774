import React, { useState } from 'react'
import { useLocale } from 'src/lib/I18nContext'
import { getInitiallySelectedL2Code } from 'src/lib/localization'
import {
  BusinessNavigationItemName,
  LanguageCode,
  LanguageDropDownItem,
  LessonsNavigationItemName,
  PageContext,
} from 'src/types'
import { BusinessThemeProvider } from 'src/lib/pageThemeContext'
import BusinessHero from "./subcomponents/BusinessHero/BusinessHero";
import { Box } from "@chatterbug/aaron";
import FullWidthBackground from "../../components/FullWidthBackground/FullWidthBackground";
import background from "../Lessons/assets/background.svg";
import backgroundPlans from "./subcomponents/BusinessPlans/assets/background.svg";
import backgroundBottom from "./subcomponents/BusinessBottom/assets/background.svg";
import BusinessFeatures from "./subcomponents/BusinessFeatures/BusinessFeatures";
import BusinessLanguages from "./subcomponents/BusinessLanguages/BusinessLanguages";
import BusinessPrices from "./subcomponents/BusinessPrices/BusinessPrices";
import WhyChatterbug from "../WhyChatterbug/WhyChatterbug";
import SectionSeparator from "../SectionSeparator/SectionSeparator";
import OurMethod from "../Lessons/subcomponents/OurMethod/OurMethod";
import BusinessPlans from "./subcomponents/BusinessPlans/BusinessPlans";
import BusinessBottom from "./subcomponents/BusinessBottom/BusinessBottom";
import BusinessClients from "./subcomponents/BusinessClients/BusinessClients";
import IntercomWidget from "../../components/IntercomWidget/IntercomWidget";

export type Props = {
  pageContext: PageContext
}

const Business: React.FC<Props> = ({
                                     pageContext,
                                   }) => {
  const locale = useLocale()
  const initialL2Code = getInitiallySelectedL2Code(locale)
  const [selectedL2Code, setSelectedL2Code] = useState<LanguageCode | null>(
    initialL2Code
  )

  const handleSelectedL2Change = (item: LanguageDropDownItem | null) =>
    setSelectedL2Code(item?.value || null)

  return (
    <BusinessThemeProvider>
      <IntercomWidget/>
      <BusinessHero
        pageContext={pageContext}
        selectedL2Code={selectedL2Code}
        onSelectedL2Change={handleSelectedL2Change}
      />
      <Box
        as="section"
        id={BusinessNavigationItemName.features}
        mt={-32}
        mb="5rem"
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={background} width={1439} height={741}>
          <Box pt="4x">
            <BusinessFeatures/>
          </Box>
        </FullWidthBackground>
      </Box>
      <BusinessLanguages/>
      <Box
        as="section"
        id={BusinessNavigationItemName.compare}
      >
        <BusinessPrices/>
      </Box>
      <BusinessClients/>
      <Box
        as="section"
        id={BusinessNavigationItemName.learning}
        mt={-32}
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={background} width={1439} height={741}>
          <Box pt="4x">
            <WhyChatterbug sectionName={BusinessNavigationItemName.howItWorks}/>
          </Box>
        </FullWidthBackground>
      </Box>
      <Box as="section" mt={88} sx={{ position: "relative", zIndex: "3" }}>
        <SectionSeparator name={LessonsNavigationItemName.method}/>
        <Box mt="3x">
          <OurMethod/>
        </Box>
      </Box>
      <Box
        as="section"
        id={BusinessNavigationItemName.pricing}
        mt="-120px"
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={backgroundPlans} width={1439} height={741}>
          <Box pt="120px" pb="13.62rem">
            <BusinessPlans/>
          </Box>
        </FullWidthBackground>
      </Box>
      <Box
        as="section"
        id={BusinessNavigationItemName.features}
        mt="-120px"
        position="relative"
        sx={{ zIndex: 2 }}
      >
        <FullWidthBackground image={backgroundBottom} width={1439} height={741}>
          <BusinessBottom
            selectedL2Code={selectedL2Code}
            onSelectedL2Change={handleSelectedL2Change}
          />
        </FullWidthBackground>
      </Box>
    </BusinessThemeProvider>
  )
}

export default Business
